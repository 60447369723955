const KGS_IN_LBS = 0.453592
const LBS_IN_KG = 2.20462

export const kgToLbs = (kg = 0) => Math.round(kg / KGS_IN_LBS)
export const lbsToKg = (lbs = 0) => Math.round(lbs / LBS_IN_KG)

export default (getView) =>
  ({ nextStepPath, formFields }) => ({
    ...getView({
      nextStepPath,
      formFields,
    }),

    handleUnitsChange(units) {
      window.analytics.track(window.getEventName("Units Clicked"), {
        units,
      })
      this.setMeasurementSystem(units)
      this.convertWeight()
      this.setSubmitAllowed(this.formValues, formFields)
    },

    convertWeight() {
      if (this.isImperial()) {
        // Don't do anything if there is nothing to convert
        // to prevent invalid values to appear
        if (!this.formValues.weightKg && this.formValues.weightKg !== 0) return

        this.formValues.weightLbs = kgToLbs(this.formValues.weightKg)
      }

      if (this.isMetric()) {
        if (!this.formValues.weightLbs && this.formValues.weightLbs !== 0) return

        this.formValues.weightKg = lbsToKg(this.formValues.weightLbs)
      }
    },

    // Perform final convertions before submit
    processFormValues() {
      const { weightLbs, weightKg } = this.formValues

      if (this.isMetric()) {
        return {
          weightKg,
          weightLbs: kgToLbs(weightKg),
        }
      }

      if (this.isImperial()) {
        return {
          weightKg: lbsToKg(weightLbs),
          weightLbs,
        }
      }
    },

    setSubmitAllowed(formValues, formFields) {
      const requiredFields =
        this.store.values.measurementSystem === "imperial" ? ["weightLbs"] : ["weightKg"]

      const allRequiredPresent = requiredFields.every((value) => {
        const formValue = formValues[value]

        if (Array.isArray(formValue)) return !!formValue.length

        return formValue && formValue !== 0
      })

      this.isSubmitAllowed = allRequiredPresent

      // Reset validation errors on units change to not confuse user
      // because we perform validation only on form submit
      formFields.forEach((field) => (this.errors[field] = null))
    },
  })

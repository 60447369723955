import Alpine from "alpinejs"
import { load, store } from "../localStorage"
import validate from "../validations"
import pipeHeader from "./pipe-header"
import optionsForm from "./options-form"
import heightForm from "./height-form"
import weightForm from "./weight-form"
import targetWeightForm from "./target-weight-form"
import loadingProgress from "./loading-progress"
import bmi from "./bmi"
import potential from "./potential"
import animations from "./animations"
import slider from "./slider"
import timer from "./timer"
import checkout from "./checkout"
import faq from "./faq"
import privacyAgreement from "./privacyAgreement"
import sticky from "./sticky"
import primer from "./primer"
import cookieConsent from "./cookie-consent"

const VIEW_STORAGE_KEY = "view"
const GLOBAL_STORAGE_KEY = "data"

const basicView = ({ nextStepPath, formFields }) => ({
  isSubmitAllowed: false,
  showSpinner: false,
  formValues: {},
  errors: {},
  showFetchError: false,

  initFormValues(formFields) {
    if (!formFields) return

    const viewData = load(VIEW_STORAGE_KEY)
    const globalData = load(GLOBAL_STORAGE_KEY)

    const isActiveErrors = viewData?.errors && viewData.path === window.location.pathname

    if (isActiveErrors) {
      this.formValues = viewData.formValues
      this.errors = viewData.errors
    } else {
      formFields.map((field) => {
        const value = globalData.values[field]

        this.formValues[field] = value
      })
    }

    this.setSubmitAllowed(this.formValues, formFields)
  },

  commonInit() {
    this.store = Alpine.store("data")

    window.addEventListener("pageshow", (event) => {
      if (event.persisted) {
        this.showSpinner = false
        this.isSubmitAllowed = true
      }
    })

    this.initFormValues(formFields)
  },

  init() {
    this.commonInit()
  },

  isSelected(name, value) {
    return Boolean(this.formValues[name]?.includes(value))
  },

  isMetric() {
    return this.store.values.measurementSystem === "metric"
  },

  isImperial() {
    return this.store.values.measurementSystem === "imperial"
  },

  setMeasurementSystem(value) {
    if (!value) return

    this.store.values.measurementSystem = value
  },

  update(field, value) {
    this.formValues[field] = value
    this.submit()
  },

  justUpdate(field, value) {
    this.formValues[field] = value
  },

  handleInput($event, field) {
    this.formValues[field] = $event.target.value && $event.target.value.trim()

    this.errors[field] = null

    this.setSubmitAllowed(this.formValues, formFields)
  },

  handleNumericInput($event, field) {
    $event.target.value = $event.target.value.replace(/[^\d]/g, "")

    this.formValues[field] = $event.target.value && Number($event.target.value)

    this.errors[field] = null

    this.setSubmitAllowed(this.formValues, formFields)
  },

  getRequiredFields(formFields) {
    // Treat all formFields as required by default
    if (Array.isArray(formFields)) {
      return formFields
    }

    // Provide the ability to pass the extended config for the formFields
    return Object.keys(formFields).map((key) => {
      if (formFields[key].required) {
        return key
      }
    })
  },

  setSubmitAllowed(formValues, formFields) {
    const requiredFields = this.getRequiredFields(formFields)
    const allRequiredPresent = requiredFields.every((value) => {
      const formValue = formValues[value]

      if (Array.isArray(formValue)) return !!formValue.length

      return formValue && formValue !== 0
    })

    this.isSubmitAllowed = allRequiredPresent
  },

  resetErrors() {
    this.errors = {}
  },

  processFormValues() {
    return this.formValues
  },

  storeViewData(data) {
    store(VIEW_STORAGE_KEY, {
      path: window.location.pathname,
      ...data,
    })
  },

  async submit(push = false) {
    const formValues = this.processFormValues()

    const { hasErrors, errors } = validate(formValues, this.store)

    if (hasErrors) {
      window.analytics.track(window.getEventName("Invalid Form Submitted"))

      this.storeViewData({ errors, formValues: this.formValues })

      this.errors = { ...this.errors, ...errors }
    } else {
      this.showSpinner = true
      this.isSubmitAllowed = false

      this.storeViewData(null)
      this.store.values = { ...this.store.values, ...formValues }

      if (!push) {
        window.location = nextStepPath
      } else if (await this.store.push()) {
        window.location = nextStepPath
      } else {
        if (this.store.emailValidationError) {
          this.store.values.email = ""
          this.errors.email = true
        }
        this.showFetchError = true
        this.showSpinner = false
      }
    }
  },
})

export default [
  { name: "view", data: basicView },
  { name: "pipeHeader", data: pipeHeader },
  { name: "optionsForm", data: optionsForm(basicView) },
  { name: "heightForm", data: heightForm(basicView) },
  { name: "weightForm", data: weightForm(basicView) },
  { name: "targetWeightForm", data: targetWeightForm(basicView) },
  { name: "loadingProgress", data: loadingProgress },
  { name: "bmi", data: bmi },
  { name: "potential", data: potential },
  { name: "animations", data: animations },
  { name: "slider", data: slider },
  { name: "timer", data: timer },
  { name: "checkout", data: checkout },
  { name: "faq", data: faq },
  { name: "privacyAgreement", data: privacyAgreement },
  { name: "sticky", data: sticky },
  { name: "primer", data: primer },
  { name: "cookieConsent", data: cookieConsent },
]

const INCH_IN_CM = 2.54
const CM_IN_FT = 30.48

const cmToFtIn = (cm = 0) => {
  const totalInch = cm / INCH_IN_CM
  const feet = Math.floor(totalInch / 12)
  const inch = totalInch - 12 * feet

  return { heightFt: Math.round(feet), heightIn: Math.round(inch) }
}

const ftInToCm = (ft = 0, inch = 0) => {
  const heightCm = Math.round(ft * CM_IN_FT + inch * INCH_IN_CM)

  return heightCm
}

export default (getView) =>
  ({ nextStepPath, formFields }) => ({
    ...getView({
      nextStepPath,
      formFields,
    }),

    handleUnitsChange(units) {
      window.analytics.track(window.getEventName("Units Clicked"), {
        units,
      })
      this.setMeasurementSystem(units)
      this.convertHeight()
      this.setSubmitAllowed(this.formValues, formFields)
    },

    convertHeight() {
      if (this.isImperial()) {
        // Don't do anything if there is nothing to convert
        // to prevent invalid values to appear
        if (!this.formValues.heightCm && this.formValues.heightCm !== 0) return

        const { heightFt, heightIn } = cmToFtIn(this.formValues.heightCm)

        this.formValues.heightFt = heightFt
        this.formValues.heightIn = heightIn
      }

      if (this.isMetric()) {
        const { heightFt, heightIn } = this.formValues
        const isFtNotSet = !heightFt && heightFt !== 0
        const isInNotSet = !heightIn && heightIn !== 0

        // Reset imperial is they were half-filled
        if (isFtNotSet || isInNotSet) {
          this.formValues.heightFt = null
          this.formValues.heightIn = null
          return
        }

        const heightCm = ftInToCm(heightFt, heightIn)

        this.formValues.heightCm = heightCm
      }
    },

    // Perform final convertions before submit
    processFormValues() {
      const { heightCm, heightFt, heightIn } = this.formValues

      if (this.isMetric()) {
        return {
          heightCm,
          ...cmToFtIn(heightCm),
        }
      }

      if (this.isImperial()) {
        return {
          heightCm: ftInToCm(heightFt, heightIn),
          heightFt,
          heightIn,
        }
      }
    },

    setSubmitAllowed(formValues, formFields) {
      const requiredFields =
        this.store.values.measurementSystem === "imperial" ? ["heightFt", "heightIn"] : ["heightCm"]

      const allRequiredPresent = requiredFields.every((value) => {
        const formValue = formValues[value]

        if (Array.isArray(formValue)) return !!formValue.length

        return formValue || formValue === 0
      })

      this.isSubmitAllowed = allRequiredPresent

      // Reset validation errors on units change to not confuse user
      // because we perform validation only on form submit
      formFields.forEach((field) => (this.errors[field] = null))
    },
  })

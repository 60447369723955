export default () => ({
  init() {
    const faqItems = document.querySelectorAll("[data-faq-item]")

    faqItems.forEach((item) => {
      const faqHead = item.querySelector("[data-faq-head]")
      const faqBody = item.querySelector("[data-faq-body]")
      const faqContent = item.querySelector("[data-faq-content]")

      faqHead.addEventListener("click", () => {
        if (!item.classList.contains("pipe-faq-active")) {
          window.analytics.track("Selected")
        }

        item.classList.toggle("pipe-faq-active")

        const bodyHeight = faqBody.getBoundingClientRect().height
        const contentHeight = faqContent.getBoundingClientRect().height

        requestAnimationFrame(() => {
          faqBody.style.height = bodyHeight ? "0px" : `${contentHeight}px`
        })

        setTimeout(() => {
          if (!bodyHeight) {
            faqHead.scrollIntoView({ behavior: "smooth" })
          }
        }, 300)
      })
    })
  },
})
